import React from "react";
import {
  Box,
  Container,
  Grid,
  Heading,
  Text,
  VStack,
  List,
  ListItem,
  ListIcon,
  Image,
  Flex,
} from "@chakra-ui/react";
import { CheckCircle } from "lucide-react";

const Section = ({ title, children }) => (
  <VStack
    align="stretch"
    spacing={4}
    p={6}
    bg="white"
    borderRadius="lg"
    boxShadow="lg"
    _hover={{ boxShadow: "xl" }}
    transition="all 0.3s"
  >
    <Heading
      as="h3"
      fontSize={["xl", "2xl"]}
      color="#000f40"
      borderBottom="2px solid"
      borderColor="blue.200"
      pb={2}
      sx={{ fontFamily: "'Montserrat', sans-serif" }}
    >
      {title}
    </Heading>
    {children}
  </VStack>
);

const MissionVisionValues = () => {
  const values = [
    {
      title: "Parteneriatul",
      description: "Fundamentul relațiilor de succes pe termen lung.",
    },
    {
      title: "Excelența în servicii",
      description: "Succesul clienților noștri este și succesul nostru.",
    },
    {
      title: "Integritatea",
      description:
        "Ne ținem întotdeauna promisiunile, acționând cu transparență și corectitudine.",
    },
    {
      title: "Echipa",
      description:
        "Forța și motorul Travel Time, esențială pentru fiecare realizare",
    },
  ];

  return (
    <Container maxW="container.xl" py={6}>
      <Grid
        templateColumns={["1fr", "1fr", "3fr 3fr"]}
        gap={8}
        alignItems="stretch"
      >
        <Flex
          display={["block", "none", "flex"]}
          position="relative"
          height="100%"
        >
          <Box
            position="sticky"
            top="2rem"
            width="100%"
            height="calc(100vh - 4rem)"
            maxHeight={["400px", "500px", "710px"]}
            borderRadius="xl"
            overflow="hidden"
          >
            <Image
              src="https://travel-time.ro/images/newImages/shutterstock_1499306735.jpg"
              alt="mission value vision image"
              width="100%"
              height="100%"
              objectFit="cover"
              borderRadius="xl"
              boxShadow="2xl"
              transition="transform 0.3s"
              _hover={{ transform: "scale(1.02)" }}
            />
            <Box
              position="absolute"
              bottom={0}
              left={0}
              right={0}
              height="50%"
              bgGradient="linear(to-t, rgba(0,15,64,0.7), transparent)"
              borderBottomRadius="xl"
              pointerEvents="none"
            />
          </Box>
        </Flex>

        <VStack spacing={8} align="stretch">
          <Section title="Misiune">
            <Text
              fontSize="md"
              color="gray.700"
              lineHeight="tall"
              sx={{ fontFamily: "'Montserrat', sans-serif" }}
            >
              Să contribuim la succesul clienților și partenerilor noștri prin
              soluții personalizate de management al călătoriilor. Ne angajăm să
              aducem valoare prin conținut relevant și suport continuu, oferind
              o experiență completă și adaptată nevoilor fiecărui client.
            </Text>
          </Section>

          <Section title="Viziune">
            <Text
              fontSize="md"
              color="gray.700"
              lineHeight="tall"
              sx={{ fontFamily: "'Montserrat', sans-serif" }}
            >
              Să devenim principalul consolidator pe piața din România pentru
              bilete de avion, servicii B2B și managementul călătoriilor,
              oferind servicii de excelență care susțin performanța și eficiența
              clienților noștri.
            </Text>
          </Section>

          <Section title="Valori">
            <List spacing={6}>
              {values.map((value, index) => (
                <ListItem key={index} display="flex" alignItems="flex-start">
                  <ListIcon as={CheckCircle} color="blue.500" mt={1} />
                  <Box>
                    <Text
                      fontWeight="bold"
                      color="#000f40"
                      sx={{ fontFamily: "'Montserrat', sans-serif" }}
                    >
                      {value.title}
                    </Text>
                    <Text
                      fontSize="md"
                      color="gray.700"
                      sx={{ fontFamily: "'Montserrat', sans-serif" }}
                    >
                      {value.description}
                    </Text>
                  </Box>
                </ListItem>
              ))}
            </List>
          </Section>
        </VStack>
      </Grid>
    </Container>
  );
};

export default MissionVisionValues;
