import React from "react";
import HeaderImage from "../../components/header-image/header-image";
// import VacantePrimaPagina from "../vacante/vacante-prima-pagina";
// import CarouselHomePageCorporate from "../../components/carousel/carousel-corporate-home-page";
// import CarouselHomePageLeisure from "../../components/carousel/carousel-leisure-home-page";
// import BlogPrimaPagina from "../blog/blog-prima-pagina";
import CookieContent from "../../components/cookie-content/cookie-content";
// import Rezerva from "../../components/rezerva/rezerva";
// import { GridPromo } from "../../components/grid-promo/grid-promo";
// import { GridPromoAfNov } from "../../components/grid-promo/grid-promoaf-nov";
// import { GridPromoKlNov } from "../../components/grid-promo/grid-promokl-nov";
import HeaderNew from "../../components/components-new-home/headerNew/header";
import HomeTextSection from "../../components/components-new-home/headerNew/headerTextAbout";
import StatBox from "../../components/components-new-home/headerNew/stats";
import CertificationsSection from "../../components/components-new-home/headerNew/certification";
import MissionVisionValues from "../../components/components-new-home/headerNew/missionvisionvalues";

const Home = () => {
  const HeaderImg1 =
    "url('https://images.unsplash.com/photo-1520437358207-323b43b50729?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80')";

  return (
    <>
      <HeaderNew />
      <HomeTextSection />
      <StatBox />
      <CertificationsSection />
      <MissionVisionValues />
      {/* <CarouselHomePageCorporate />
      <GridPromo /> */}
      {/* <CarouselHomePageLeisure />
      <VacantePrimaPagina /> */}
      {/* <GridPromoAfNov />
      <GridPromoKlNov /> */}
      {/* <BlogPrimaPagina /> */}
      {/* <HeaderImage HeaderImg={HeaderImg1} /> */}
      <CookieContent />
    </>
  );
};

export default Home;
