import React from "react";
import {
  Box,
  Container,
  Heading,
  Text,
  Grid,
  GridItem,
  VStack,
  HStack,
  Flex,
  Circle,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  Globe,
  LineChart,
  CreditCard,
  Smartphone,
  Cpu,
  ArrowUpRight,
  Hotel,
  Car,
  Train,
  Plane,
  PieChart,
  Wallet,
  Bell,
  Bot,
} from "lucide-react";

const TechSolutionsSection = () => {
  const bgColor = useColorModeValue("white", "gray.900");
  const cardBg = useColorModeValue("white", "gray.800");
  const accentBg = useColorModeValue("blue.50", "blue.900");

  const solutions = [
    {
      title: "Soluții de rezervare online",
      icon: Globe,
      description:
        "Soluțiile multiple de rezervare online oferite de Travel Time asigură acces la cele mai bune tarife regionale și locale, ajutând companiile să maximizeze economiile pentru fiecare călătorie. Fie că este vorba de zboruri, hoteluri, închirieri auto sau servicii feroviare, instrumentele noastre permit rezervări rapide și eficiente către aproape orice destinație și punct de plecare.Prin înțelegerea preferințelor clienților și a politicilor și obiectivelor companiei, recomandăm cea mai potrivită soluție OBT (Online Booking Tool). Adoptăm o abordare personalizată, aliniată cu obiectivele tale, optimizând în același timp eficiența costurilor.",
      features: [
        { icon: Plane, text: "Zboruri" },
        { icon: Hotel, text: "Hoteluri" },
        { icon: Car, text: "Închirieri auto" },
        { icon: Train, text: "Servicii feroviare" },
      ],
    },
    {
      title: "Analize detaliate și perspective complete",
      icon: LineChart,
      description:
        "Obține o imagine clară asupra performanței programului tău de călătorii cu ajutorul instrumentelor noastre avansate de analiză a datelor. Colectăm, procesăm și prezentăm indicatori cheie în rapoarte detaliate, oferind o privire de ansamblu asupra tendințelor de cheltuieli, nivelurilor de conformitate și oportunităților de economisire.De la detalii granulate până la perspective generale, sistemul nostru de raportare echipează managerii de călătorii de afaceri cu informațiile necesare pentru a optimiza operațiunile, a rafina strategiile și a obține îmbunătățiri măsurabile.",
      features: [
        { icon: PieChart, text: "Rapoarte detaliate" },
        { icon: LineChart, text: "Tendințe de cheltuieli" },
        { icon: Bot, text: "Analiză automată" },
        { icon: ArrowUpRight, text: "Optimizare continuă" },
      ],
    },
    {
      title: "Monitorizarea cheltuielilor simplificată",
      icon: CreditCard,
      description:
        "Urmărirea cheltuielilor de călătorie nu a fost niciodată mai ușoară. Tehnologia noastră oferă monitorizare în timp real a cheltuielilor, asigurând o imagine transparentă a modului în care fondurile sunt alocate pe tipuri de servicii în cadrul programului tău de călătorii.Dincolo de cifre, analizăm comportamentul călătorilor și al organizatorilor pentru a identifica lacunele de conformitate, a detecta tendințele de respectare a politicilor și a evidenția oportunitățile de optimizare a utilizării bugetului.",
      features: [
        { icon: Wallet, text: "Monitorizare în timp real" },
        { icon: LineChart, text: "Analiză comportament" },
        { icon: PieChart, text: "Rapoarte detaliate" },
        { icon: Bot, text: "Detectare tendințe" },
      ],
    },
    {
      title: "Mobilitate și suport în timp real",
      icon: Smartphone,
      description:
        "Proiectate pentru călătorii moderni, soluțiile noastre mobile intuitive oferă confort și control direct la îndemână. Gestionează itinerariile din mers, primește notificări instantanee pentru actualizări de program și accesează un tablou de bord complet dedicat reponsabilitătii pentru siguranța călătoriilor de afaceri.Pentru managerii de programe de călătorii în scop de afaceri, soluțiile noastre oferă instrumente puternice pentru a urmări locațiile călătorilor în timp real, a anticipa perturbările și a furniza suport proactiv atunci când este cel mai important.",
      features: [
        { icon: Bell, text: "Notificări instant" },
        { icon: Globe, text: "Localizare în timp real" },
        { icon: Bot, text: "Suport proactiv" },
        { icon: LineChart, text: "Dashboard complet" },
      ],
    },
    {
      title: "Eficiență prin automatizări",
      icon: Cpu,
      description:
        "La Travel Time, valorificăm puterea automatizării pentru a oferi eficiență și economii fără precedent. Roboții noștri Amadeus simplifică procesele de rezervare, fluxurile de plăți și raportările automate, în timp ce instrumentul nostru propriu, Repricer, caută constant tarife mai avantajoase, reemițând bilete la costuri reduse ori de câte ori este posibil.Această combinație între automatizarea de ultimă generație și expertiza umană asigură că programul tău de călătorii rămâne agil, rentabil și perfect aliniat cu obiectivele afacerii tale.",
      features: [
        { icon: Bot, text: "Roboți Amadeus" },
        { icon: ArrowUpRight, text: "Repricer automatizat" },
        { icon: LineChart, text: "Raportări automate" },
        { icon: CreditCard, text: "Plăți automatizate" },
      ],
    },
  ];

  return (
    <Box py={{ base: 12, md: 20 }} bg={bgColor}>
      <Container maxW="container.xl">
        <VStack spacing={16}>
          {/* Header */}
          <Box textAlign="center" maxW="800px">
            <Heading
              as="h2"
              size="2xl"
              mb={4}
              color="blue.600"
              fontFamily="Montserrat"
            >
              Soluții inovatoare de tehnologie pentru călătorii de afaceri
            </Heading>
            <Text fontSize="xl" color="gray.600" fontFamily="Montserrat">
              Tehnologie avansată pentru o experiență de călătorie optimizată
            </Text>
          </Box>

          {/* Solutions Grid */}
          <Grid templateColumns={{ base: "1fr", lg: "repeat(2, 1fr)" }} gap={8}>
            {solutions.map((solution, idx) => (
              <GridItem key={idx}>
                <Box
                  h="full"
                  bg={cardBg}
                  p={8}
                  borderRadius="xl"
                  boxShadow="xl"
                  position="relative"
                  overflow="hidden"
                  transition="all 0.3s"
                  _hover={{
                    transform: "translateY(-4px)",
                    boxShadow: "2xl",
                  }}
                >
                  {/* Solution Header */}
                  <HStack spacing={4} mb={6}>
                    <Circle size="48px" bg="blue.500" color="white">
                      <solution.icon size={24} />
                    </Circle>
                    <Heading
                      as="h3"
                      size="lg"
                      fontFamily="Montserrat"
                      color="blue.700"
                    >
                      {solution.title}
                    </Heading>
                  </HStack>

                  {/* Description */}
                  <Text
                    color="gray.600"
                    mb={6}
                    fontSize="lg"
                    lineHeight="tall"
                    fontFamily="Montserrat"
                  >
                    {solution.description}
                  </Text>

                  {/* Features Grid */}
                  <Grid
                    templateColumns="repeat(2, 1fr)"
                    gap={4}
                    bg={accentBg}
                    p={4}
                    borderRadius="lg"
                  >
                    {solution.features.map((feature, featureIdx) => (
                      <HStack
                        key={featureIdx}
                        spacing={3}
                        p={2}
                        transition="all 0.2s"
                        _hover={{
                          transform: "translateX(4px)",
                        }}
                      >
                        <feature.icon size={18} color="#3182CE" />
                        <Text
                          fontSize="md"
                          fontWeight="medium"
                          color="gray.700"
                          fontFamily="Montserrat"
                        >
                          {feature.text}
                        </Text>
                      </HStack>
                    ))}
                  </Grid>

                  {/* Background Decoration */}
                  <Box
                    position="absolute"
                    right="-20"
                    bottom="-20"
                    opacity={0.03}
                  >
                    <solution.icon size={150} />
                  </Box>
                </Box>
              </GridItem>
            ))}
          </Grid>

          {/* Bottom Banner */}
          <Box
            w="full"
            p={8}
            bg="blue.500"
            color="white"
            borderRadius="xl"
            position="relative"
            overflow="hidden"
          >
            <Box position="absolute" right="-10" top="-10" opacity={0.1}>
              <Cpu size={200} />
            </Box>
            <VStack
              spacing={4}
              position="relative"
              align="center"
              maxW="800px"
              mx="auto"
            >
              <Heading size="lg" textAlign="center" fontFamily="Montserrat">
                Tehnologie și Expertiză Unite
              </Heading>
              <Text fontSize="lg" textAlign="center" fontFamily="Montserrat">
                Combinăm cele mai avansate soluții tehnologice cu expertiza
                umană pentru a oferi servicii de călătorie excepționale.
              </Text>
            </VStack>
          </Box>
        </VStack>
      </Container>
    </Box>
  );
};

export default TechSolutionsSection;
