import {
  Box,
  Container,
  Heading,
  Text,
  Grid,
  GridItem,
  VStack,
  HStack,
  Flex,
  Center,
  useBreakpointValue,
  Circle,
  Stat,
  StatNumber,
  StatLabel,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  Users,
  Shield,
  Globe2,
  Clock,
  Award,
  BookOpen,
  HeartHandshake,
  UserCheck,
  AlarmCheck,
  Binary,
  Phone,
  BadgeCheck,
} from "lucide-react";

export const ContentCapabilities = () => {
  const subHeadingSize = useBreakpointValue({
    base: "xl",
    md: "2xl",
    lg: "3xl",
  });

  const bgColor = useColorModeValue("gray.50", "gray.900");
  const cardBg = useColorModeValue("white", "gray.800");
  const statBg = useColorModeValue("blue.50", "blue.900");
  const borderColor = useColorModeValue("gray.200", "gray.700");

  const stats = [
    { number: "30+", label: "Profesioniști", icon: Users },
    { number: "15+", label: "Ani Experiență", icon: Clock },
    { number: "24/7", label: "Suport", icon: Phone },
    { number: "100%", label: "Dedicare", icon: HeartHandshake },
  ];

  const sections = [
    {
      title: "Echipă dedicată",
      icon: UserCheck,
      content: [
        "Consultanții noștri au o vastă experiență în toate capitolele managementului călătoriilor corporate, asigurând fiecărui client servicii complete și personalizate, menținând totodată cele mai înalte standarde de servisare a clienților.",
        "În funcție de volumul de călătorii al organizației tale, Travel Time alocă o echipă dedicată de 2 până la 9 consultanți profesioniști, complet dedicați optimizării programului tău de călătorii.",
        "Dincolo de competențele lor tehnice, membrii echipei noastre sunt orientați spre rezultate și profund dedicați succesului clienților. Ani de colaborare au format o echipă coezivă și agilă, capabilă să ia decizii rapide și bine informate, chiar și în situații mai complicate.",
      ],
    },
    {
      title: "Expertiză de neegalat",
      icon: Award,
      content: [
        "Echipa Travel Time este formată din peste 30 de profesioniști cu înaltă calificare și experiență vastă, fluenți în limba engleză și cu abilităti în alte câteva, asigurând o coordonare perfectă a proiectelor pe multiple regiuni și domenii.",
        "Consultanții noștri în călătoriile de afaceri au, în medie, 15 ani de experiență în industrie, mulți dintre ei având un background extins în ticketing, ceea ce le oferă cunoștințe aprofundate pentru a gestiona scenarii complexe de călătorie.",
        "Într-o industrie caracterizată de schimbări constante, personalul nostru, bine informat și adaptabil, asigură gestionarea nevoilor tale de călătorie cu precizie și previziune. Angajamentul Travel Time pentru investiții regulate în formarea echipei ne menține mereu cu un pas înainte, pregătiți să oferim rezultate remarcabile.",
      ],
    },
    {
      title:
        "Responsabilitatea pentru siguranța călătoriilor în interes de afaceri",
      icon: Shield,
      content: [
        "La Travel Time, responsabilitatea pentru siguranța călătorilor reprezintă o prioritate fundamentală în abordarea noastră de management al călătoriilor de afaceri.",
        "Prin utilizarea instrumentelor avansate, putem identifica în timp real locațiile călătorilor, permițându-ne să gestionăm proactiv perturbările și să garantăm siguranța acestora.",
        "Protocolul nostru de asistență 24/7 oferă managerilor de călătorii posibilitatea de a comunica fără întreruperi în timpul situațiilor de urgență, furnizând soluții imediate și suport constant. Fie că este vorba de o întârziere neprevăzută sau de un incident critic, oferim suport, siguranță și încredere pentru fiecare călător, în orice moment.",
      ],
    },
  ];

  const features = [
    { icon: Globe2, text: "Acoperire Globală" },
    { icon: BookOpen, text: "Formare Continuă" },
    { icon: Binary, text: "Sisteme Avansate" },
    { icon: AlarmCheck, text: "Răspuns Rapid" },
    { icon: BadgeCheck, text: "Certificări" },
    { icon: Shield, text: "Siguranță Garantată" },
  ];
  return (
    <>
      <Box
        position="relative"
        w="full"
        py={{ base: 8, md: 12 }}
        display="flex"
        justifyContent="center"
      >
        <Box
          position="relative"
          w={{ base: "100%", md: "75%" }}
          h={{ base: "250px", md: "500px" }}
          borderRadius={{ base: "none", md: "xl" }}
          overflow="hidden"
          boxShadow={{ base: "none", md: "xl" }}
        >
          {/* Image Container */}
          <Box
            w="full"
            h="full"
            bgColor="gray.200"
            backgroundImage="url('https://travel-time.ro/images/newImages/shutterstock_2177147503.jpg')"
            backgroundPosition="center"
            backgroundRepeat="no-repeat"
            backgroundSize="cover"
          />

          {/* Gradient Overlay for text */}
          <Box
            position="absolute"
            bottom="0"
            left="0"
            right="0"
            h="50%"
            bgGradient="linear(to-t, blackAlpha.800, blackAlpha.400, transparent)"
          />

          {/* Title Container */}
          <Center
            position="absolute"
            bottom="0"
            left="0"
            right="0"
            p={{ base: 6, md: 8 }}
            align="flex-start"
            justify="flex-start"
          >
            <Heading
              as="h2"
              size={subHeadingSize}
              color="white"
              fontFamily="Montserrat"
              textShadow="2px 2px 4px rgba(0,0,0,0.4)"
              maxW="container.lg"
            >
              Capabilități - Îmbinăm tehnologia cu expertiza profesională
            </Heading>
          </Center>
        </Box>
      </Box>

      <Box py={{ base: 12, md: 20 }} bg={bgColor}>
        <Container maxW="container.xl">
          <VStack spacing={16}>
            {/* Stats Section */}
            <Grid
              templateColumns={{ base: "repeat(2, 1fr)", md: "repeat(4, 1fr)" }}
              gap={6}
              w="full"
            >
              {stats.map((stat, idx) => (
                <GridItem key={idx}>
                  <Box
                    bg={statBg}
                    p={6}
                    borderRadius="xl"
                    textAlign="center"
                    transition="all 0.3s"
                    _hover={{ transform: "translateY(-4px)" }}
                  >
                    <VStack spacing={3}>
                      <Circle size="50px" bg="blue.500" color="white">
                        <stat.icon size={24} />
                      </Circle>
                      <Stat>
                        <StatNumber fontSize="3xl" color="blue.600">
                          {stat.number}
                        </StatNumber>
                        <StatLabel fontSize="sm" color="gray.600">
                          {stat.label}
                        </StatLabel>
                      </Stat>
                    </VStack>
                  </Box>
                </GridItem>
              ))}
            </Grid>

            {/* Main Content Sections */}
            {sections.map((section, idx) => (
              <Box
                key={idx}
                w="full"
                bg={cardBg}
                borderRadius="xl"
                boxShadow="xl"
                overflow="hidden"
              >
                <Grid templateColumns={{ base: "1fr", lg: "1fr 3fr" }} gap={8}>
                  <GridItem
                    bg="blue.500"
                    p={8}
                    color="white"
                    position="relative"
                    overflow="hidden"
                  >
                    <Box
                      position="absolute"
                      right="-20%"
                      bottom="-20%"
                      opacity={0.2}
                    >
                      <section.icon size={200} />
                    </Box>
                    <VStack align="start" spacing={4} position="relative">
                      <section.icon size={40} />
                      <Heading as="h3" size="lg" fontFamily="Montserrat">
                        {section.title}
                      </Heading>
                    </VStack>
                  </GridItem>

                  <GridItem p={8}>
                    <VStack align="start" spacing={6}>
                      {section.content.map((text, textIdx) => (
                        <Text
                          key={textIdx}
                          fontSize="lg"
                          color="gray.700"
                          lineHeight="tall"
                        >
                          {text}
                        </Text>
                      ))}
                    </VStack>
                  </GridItem>
                </Grid>
              </Box>
            ))}

            {/* Features Grid */}
            <Grid
              templateColumns={{
                base: "repeat(2, 1fr)",
                md: "repeat(3, 1fr)",
                lg: "repeat(6, 1fr)",
              }}
              gap={4}
              w="full"
            >
              {features.map((feature, idx) => (
                <GridItem key={idx}>
                  <VStack
                    p={4}
                    bg={cardBg}
                    borderRadius="lg"
                    borderWidth="1px"
                    borderColor={borderColor}
                    spacing={3}
                    transition="all 0.3s"
                    _hover={{
                      transform: "translateY(-2px)",
                      shadow: "md",
                      borderColor: "blue.200",
                    }}
                  >
                    <Circle size="40px" bg="blue.50">
                      <feature.icon size={20} color="#3182CE" />
                    </Circle>
                    <Text
                      fontSize="sm"
                      fontWeight="medium"
                      color="gray.700"
                      textAlign="center"
                    >
                      {feature.text}
                    </Text>
                  </VStack>
                </GridItem>
              ))}
            </Grid>
          </VStack>
        </Container>
      </Box>
    </>
  );
};

export default ContentCapabilities;
