// import {
//   Box,
//   Container,
//   Text,
//   Divider,
//   useBreakpointValue,
// } from "@chakra-ui/react";

// const HomeTextSection = () => {
//   const fontSize = useBreakpointValue({ base: "17px", md: "22px" });
//   return (
//     <>
//       <Container maxW="container.xl" py={5}>
//         <Box
//           width={["100%", "75%"]} // Width is 100% on mobile, 75% from md breakpoint
//           mx="auto"
//           p={8}
//           bg="white"
//         >
//           <Text
//             fontSize={["md", "lg"]}
//             lineHeight="tall"
//             color="gray.700"
//             sx={{
//               fontSize: fontSize,
//               lineHeight: "33px",
//               fontFamily: "'Montserrat', sans-serif",
//               color: "#000f40",
//               marginBottom: "20px",

//             }}
//           >
//             <span style={{ color: "black", fontWeight: "bold" }}>
//               TRAVEL TIME
//             </span>{" "}
//             o companie de top cu soluții de management al călătoriilor corporate
//             și B2B – și-a construit reputația oferind servicii de excelență cu
//             consecvență. Condusă de o filozofie concentrată pe satisfacția
//             clientului și susținută de tehnologie inovatoare, Travel Time
//             modelează viitorul managementului călătoriilor corporate. Cu o
//             viziune strategică orientată spre viitor, Travel Time se adaptează
//             rapid schimbărilor accelerate din peisajul modern al călătoriilor de
//             afaceri. Misiunea noastră este să oferim valoare adăugată măsurabilă
//             prin soluții personalizate de management al călătoriilor, punând
//             accent pe economii de costuri, tehnologie robotică de ultimă
//             generație și o experiență îmbunătățită pentru pasageri.
//           </Text>
//           <Text
//             fontSize={["md", "lg"]}
//             lineHeight="tall"
//             color="gray.700"
//             mt={4}
//             sx={{
//               fontSize: fontSize,
//               lineHeight: "33px",
//               fontFamily: "'Montserrat', sans-serif",
//               color: "#000f40",
//               marginBottom: "20px",
//             }}
//           >
//             Îmbinând perfect expertiza cu inovația, Travel Time concurează la
//             cel mai înalt nivel, oferind servicii complete de călătorie,
//             adaptate nevoilor de afaceri și dinamismului de astăzi. Prioritizând
//             performanța și satisfacția, ne străduim continuu să redefinim
//             managementul călătoriilor corporate.
//           </Text>
//         </Box>
//       </Container>

//       <Divider width={["100%", "75%"]} mx="auto" />
//     </>
//   );
// };

// export default HomeTextSection;

import React from "react";
import {
  Box,
  Container,
  Heading,
  Text,
  Grid,
  GridItem,
  VStack,
  HStack,
  Flex,
  Circle,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  Plane,
  BarChart,
  Globe,
  Shield,
  Sparkles,
  Clock,
  ArrowUpRight,
} from "lucide-react";

const HomeTextSection = () => {
  const bgColor = useColorModeValue("white", "gray.900");
  const cardBg = useColorModeValue("blue.50", "blue.900");
  const highlightColor = useColorModeValue("blue.500", "blue.300");

  const features = [
    {
      icon: Globe,
      title: "Expertiză Globală",
      description: "Soluții complete de călătorie la nivel internațional",
    },
    {
      icon: Sparkles,
      title: "Inovație",
      description: "Tehnologie robotică de ultimă generație",
    },
    {
      icon: Shield,
      title: "Siguranță",
      description: "Prioritate maximă pentru securitatea călătorilor",
    },
    {
      icon: BarChart,
      title: "Performanță",
      description: "Rezultate măsurabile și economii dovedite",
    },
  ];

  return (
    <Box bg={bgColor} position="relative" overflow="hidden">
      {/* Background Decorative Elements */}
      <Box
        position="absolute"
        right="-5%"
        top="0"
        height="100%"
        width="50%"
        opacity={0.03}
        transform="rotate(-15deg)"
      >
        <Plane size={900} />
      </Box>

      <Container maxW="container.xl" py={{ base: 16, md: 24 }}>
        <Grid
          templateColumns={{ base: "1fr", lg: "1.2fr 0.8fr" }}
          gap={{ base: 12, lg: 16 }}
        >
          {/* Left Content Section */}
          <GridItem>
            <VStack align="start" spacing={10}>
              <Box position="relative">
                <Circle
                  size="60px"
                  bg="blue.500"
                  color="white"
                  position="absolute"
                  top="-20px"
                  left="-20px"
                  opacity={0.1}
                >
                  <Clock size={30} />
                </Circle>
                <Heading
                  as="h1"
                  size="2xl"
                  color="blue.600"
                  fontFamily="Inter"
                  lineHeight="1.2"
                  mb={4}
                >
                  TRAVEL TIME
                </Heading>
                <Text
                  fontSize="xl"
                  fontWeight="bold"
                  color={highlightColor}
                  mb={6}
                  fontFamily="Montserrat"
                >
                  Redefinim viitorul călătoriilor corporate
                </Text>
              </Box>

              <Text
                fontSize="lg"
                color="gray.700"
                lineHeight="tall"
                fontFamily="Montserrat"
              >
                O companie de top cu soluții de management al călătoriilor
                corporate și B2B – și-a construit reputația oferind servicii de
                excelență cu consecvență. Condusă de o filozofie concentrată pe
                satisfacția clientului și susținută de tehnologie inovatoare,
                Travel Time modelează viitorul managementului călătoriilor
                corporate.
              </Text>

              <Text
                fontSize="lg"
                color="gray.700"
                lineHeight="tall"
                fontFamily="Montserrat"
              >
                Cu o viziune strategică orientată spre viitor, Travel Time se
                adaptează rapid schimbărilor accelerate din peisajul modern al
                călătoriilor de afaceri. Misiunea noastră este să oferim valoare
                adăugată măsurabilă prin soluții personalizate de management al
                călătoriilor, punând accent pe economii de costuri, tehnologie
                robotică de ultimă generație și o experiență îmbunătățită pentru
                pasageri.
              </Text>

              <Text
                fontSize="lg"
                color="gray.700"
                lineHeight="tall"
                fontFamily="Montserrat"
              >
                Îmbinând perfect expertiza cu inovația, Travel Time concurează
                la cel mai înalt nivel, oferind servicii complete de călătorie,
                adaptate nevoilor de afaceri și dinamismului de astăzi.
                Prioritizând performanța și satisfacția, ne străduim continuu să
                redefinim managementul călătoriilor corporate.
              </Text>
            </VStack>
          </GridItem>

          {/* Right Features Section */}
          <GridItem>
            <VStack spacing={6} align="stretch">
              {features.map((feature, idx) => (
                <Box
                  key={idx}
                  p={5}
                  bg={cardBg}
                  borderRadius="xl"
                  position="relative"
                  overflow="hidden"
                  transition="all 0.3s"
                  _hover={{
                    transform: "translateX(8px)",
                    boxShadow: "xl",
                  }}
                >
                  <HStack spacing={4}>
                    <Circle size="48px" bg="white">
                      <feature.icon size={24} color="#3182CE" />
                    </Circle>
                    <VStack align="start" spacing={0}>
                      <Text
                        fontSize="lg"
                        fontWeight="bold"
                        color="blue.700"
                        fontFamily="Montserrat"
                      >
                        {feature.title}
                      </Text>
                      <Text color="gray.600" fontFamily="Montserrat">
                        {feature.description}
                      </Text>
                    </VStack>
                  </HStack>
                  <Box position="absolute" right="-4" bottom="-4" opacity={0.1}>
                    <ArrowUpRight size={100} />
                  </Box>
                </Box>
              ))}

              {/* Additional Highlight Box */}
              <Box
                mt={6}
                p={6}
                bg="blue.500"
                color="white"
                borderRadius="xl"
                position="relative"
                overflow="hidden"
              >
                <Box
                  position="absolute"
                  right="-20px"
                  top="-20px"
                  opacity={0.1}
                >
                  <Sparkles size={120} />
                </Box>
                <VStack align="start" spacing={3}>
                  <Heading size="md" fontFamily="Montserrat">
                    Tehnologie Inovatoare
                  </Heading>
                  <Text fontSize="sm" fontFamily="Montserrat">
                    Combinăm expertiza umană cu cele mai avansate soluții
                    tehnologice pentru a oferi servicii de excepție.
                  </Text>
                </VStack>
              </Box>
            </VStack>
          </GridItem>
        </Grid>
      </Container>
    </Box>
  );
};

export default HomeTextSection;
