import React from "react";
import HeaderConsultancy from "../../components/components-new-consultanta/headerConsultancy";
import ConsultingSection from "../../components/components-new-consultanta/strategicConsultancy";
import TravelPolicySection from "../../components/components-new-consultanta/policyTravel";
import FutureSection from "../../components/components-new-consultanta/futurePerspective";
import ContentCapabilities from "../../components/components-new-consultanta/contentCapabilities";
import BenefitsSection from "../../components/components-new-consultanta/benefitsSection";
import TechSolutionsSection from "../../components/components-new-consultanta/solutionsSection";

const Consultancy = () => {
  return (
    <>
      <HeaderConsultancy />
      <ConsultingSection />
      <TravelPolicySection />
      <FutureSection />
      <ContentCapabilities />
      <BenefitsSection />
      <TechSolutionsSection />
    </>
  );
};

export default Consultancy;
