import React from "react";
import HeaderServices from "../../components/components-new-servicii/headerServices";
import ServicesPage from "../../components/components-new-servicii/servicesPage";
import EventCategories from "../../components/components-new-servicii/eventsCategories";

const Services = () => {
  return (
    <>
      {/* <HeaderServices /> */}
      <ServicesPage />
      <EventCategories />
    </>
  );
};

export default Services;
