import React from "react";
import {
  Box,
  Container,
  Heading,
  Text,
  Grid,
  GridItem,
  VStack,
  HStack,
  Circle,
  Divider,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  LineChart,
  Target,
  TrendingUp,
  Users,
  Lightbulb,
  ArrowUpRight,
} from "lucide-react";

const ConsultingSection = () => {
  const bgColor = useColorModeValue("white", "gray.900");
  const accentColor = useColorModeValue("blue.500", "blue.300");
  const textColor = useColorModeValue("gray.700", "gray.200");
  const borderColor = useColorModeValue("gray.200", "gray.700");

  const processSteps = [
    {
      icon: Target,
      title: "Analiză Aprofundată",
      text: "Evaluăm tehnologiile, procesele și comportamentele existente",
    },
    {
      icon: LineChart,
      title: "Optimizare",
      text: "Identificăm oportunități de economii și eficientizare",
    },
    {
      icon: Users,
      title: "Colaborare",
      text: "Lucrăm împreună pentru implementarea soluțiilor",
    },
    {
      icon: TrendingUp,
      title: "Rezultate",
      text: "Monitorizăm și măsurăm impactul schimbărilor",
    },
  ];

  return (
    <Box py={{ base: 12, md: 20 }} bg={bgColor}>
      <Container maxW="container.xl">
        <Grid
          templateColumns={{ base: "1fr", lg: "1fr 1px 1fr" }}
          gap={{ base: 10, lg: 0 }}
        >
          {/* Left Section - Main Content */}
          <GridItem>
            <VStack align="start" spacing={6} pr={{ base: 0, lg: 12 }}>
              <HStack spacing={3}>
                <Lightbulb size={32} color="#3182CE" />
                <Heading
                  as="h2"
                  size="xl"
                  color="blue.600"
                  fontFamily="Montserrat"
                >
                  Consultanță strategică
                </Heading>
              </HStack>

              <Text
                fontSize="lg"
                color={textColor}
                lineHeight="tall"
                fontFamily="Montserrat"
              >
                La Travel Time, analizăm în profunzime fiecare aspect al
                programului tău de călătorii de afaceri. De la evaluarea
                tehnologiilor actuale, proceselor, relațiilor cu furnizorii și
                comportamentelor de rezervare, până la identificarea
                ineficiențelor și zonelor de îmbunătățire, serviciile noastre de
                consultanță strategică scot la lumină oportunități de economii
                și oferă informații în urma cărora putem lua decizii.
              </Text>

              <Text
                fontSize="lg"
                color={textColor}
                lineHeight="tall"
                fontFamily="Montserrat"
              >
                Echipa noastră de experți colaborează îndeaproape cu organizația
                ta pentru a redefini programele de călătorii de afaceri,
                aliniindu-le cu obiectivele corporative și îmbunătățind
                conformitatea și satisfacția călătorilor. Prin utilizarea
                tehnologiilor de vârf și a instrumentelor de prognoză,
                implementăm soluții personalizate menite să optimizeze programul
                de călătorii și să reducă costurile.
              </Text>

              <Text
                fontSize="lg"
                color={textColor}
                lineHeight="tall"
                fontFamily="Montserrat"
              >
                Mai mult, rămânem mereu cu un pas înaintea tendințelor din
                industrie pentru a te ajuta să anticipezi provocările viitoare
                și să valorifici oportunitățile emergente. Fie că este vorba de
                negocierea unor acorduri mai avantajoase cu furnizorii,
                îmbunătățirea experienței călătorilor sau pregătirea pentru
                noile dezvoltări în domeniul călătoriilor corporate, abordarea
                noastră strategică garantează rezultate măsurabile și un impact
                durabil.
              </Text>
            </VStack>
          </GridItem>

          {/* Center Divider */}
          <GridItem display={{ base: "none", lg: "block" }}>
            <Box w="1px" h="full" bg={borderColor} />
          </GridItem>

          {/* Right Section - Process Steps */}
          <GridItem>
            <VStack align="stretch" spacing={8} pl={{ base: 0, lg: 12 }}>
              <Box
                p={6}
                bg="blue.500"
                color="white"
                borderRadius="xl"
                position="relative"
                overflow="hidden"
              >
                <Box
                  position="absolute"
                  right="-20px"
                  top="-20px"
                  opacity={0.1}
                >
                  <ArrowUpRight size={120} />
                </Box>
                <Heading size="md" mb={3} fontFamily="Montserrat">
                  Proces de Consultanță
                </Heading>
                <Text fontSize="md" fontFamily="Montserrat">
                  Abordarea noastră sistematică asigură rezultate optime pentru
                  programul tău de călătorii.
                </Text>
              </Box>

              <VStack spacing={6} align="stretch">
                {processSteps.map((step, idx) => (
                  <HStack
                    key={idx}
                    spacing={4}
                    p={6}
                    borderWidth="1px"
                    borderColor={borderColor}
                    borderRadius="lg"
                    transition="all 0.3s"
                    _hover={{
                      transform: "translateX(8px)",
                      borderColor: accentColor,
                      boxShadow: "md",
                    }}
                  >
                    <Circle size="40px" bg="blue.50">
                      <step.icon size={20} color="#3182CE" />
                    </Circle>
                    <VStack align="start" spacing={1}>
                      <Text
                        fontWeight="bold"
                        color="blue.600"
                        fontFamily="Montserrat"
                      >
                        {step.title}
                      </Text>
                      <Text
                        color={textColor}
                        fontSize="sm"
                        fontFamily="Montserrat"
                      >
                        {step.text}
                      </Text>
                    </VStack>
                  </HStack>
                ))}
              </VStack>
            </VStack>
          </GridItem>
        </Grid>
      </Container>
    </Box>
  );
};

export default ConsultingSection;
