import React from "react";
import {
  Box,
  Container,
  SimpleGrid,
  VStack,
  Text,
  Image,
  Flex,
} from "@chakra-ui/react";
import { Award, Shield, Star, Trophy } from "lucide-react";

const CertificationBox = ({ icon: Icon, title, description, year }) => (
  <Box
    bg="white"
    p={6}
    borderRadius="lg"
    boxShadow="lg"
    transition="all 0.3s"
    _hover={{
      transform: "translateY(-5px)",
      boxShadow: "xl",
    }}
  >
    <VStack spacing={4} align="center">
      <Flex
        w="70px"
        h="70px"
        bg="blue.50"
        borderRadius="full"
        align="center"
        justify="center"
        mb={2}
      >
        <Icon size={35} color="#000f40" strokeWidth={1.5} />
      </Flex>
      <Text fontSize="lg" fontWeight="bold" color="#000f40" textAlign="center">
        {title}
      </Text>
      <Text fontSize="sm" color="gray.600" textAlign="center">
        {description}
      </Text>
      {year && (
        <Text fontSize="sm" color="blue.500" fontWeight="medium">
          {year}
        </Text>
      )}
    </VStack>
  </Box>
);

const LogoGrid = () => (
  <SimpleGrid columns={[2, 3, 4, 5]} spacing={8} py={8} px={4}>
    {[1, 2, 3, 4, 5].map((index) => (
      <Flex
        key={index}
        h="100px"
        bg="white"
        borderRadius="md"
        p={4}
        align="center"
        justify="center"
        transition="all 0.3s"
        _hover={{ transform: "scale(1.05)" }}
      >
        <Box
          as="img"
          src={`https://bookingtime.eu/assets/logoairlines/Turkish-Airlines-logo.jpg`}
          alt={`Partner logo ${index}`}
          maxW="100%"
          maxH="100%"
          objectFit="contain"
        />
      </Flex>
    ))}
  </SimpleGrid>
);

const CertificationsSection = () => {
  const certifications = [
    {
      icon: Shield,
      title: "ISO Certification",
      description: "International Quality Management Standard",
      year: "2023",
    },
    {
      icon: Award,
      title: "Industry Award",
      description: "Excellence in Service Delivery",
      year: "2022",
    },
    {
      icon: Star,
      title: "Top Rated Partner",
      description: "Recognized for Outstanding Performance",
      year: "2023",
    },
    {
      icon: Trophy,
      title: "Best in Category",
      description: "Leading Industry Recognition",
      year: "2023",
    },
  ];

  return (
    <Container maxW="container.lg" py={12}>
      <Box width={["100%", "100%", "75%"]} mx="auto">
        <VStack spacing={12}>
          {/* Certifications Grid */}
          <SimpleGrid columns={[1, 2, 2, 4]} spacing={8} w="full">
            {certifications.map((cert, index) => (
              <CertificationBox
                as="img"
                key={index}
                icon={cert.icon}
                title={cert.title}
                description={cert.description}
                year={cert.year}
              />
            ))}
          </SimpleGrid>

          {/* Partner Logos Grid */}
          <Box w="full">
            <Text
              fontSize="xl"
              fontWeight="bold"
              color="#000f40"
              textAlign="center"
              mb={6}
            >
              Our Partners & Accreditations
            </Text>
            <LogoGrid />
          </Box>
        </VStack>
      </Box>
    </Container>
  );
};

export default CertificationsSection;



// import React from "react";
// import {
//   Box,
//   Container,
//   SimpleGrid,
//   VStack,
//   Text,
//   Image,
//   Flex,
// } from "@chakra-ui/react";
// import { Award, Shield, Star, Trophy } from "lucide-react";

// const CertificationBox = ({ imageSrc, title, description, year }) => (
//   <Box
//     bg="white"
//     p={6}
//     borderRadius="lg"
//     boxShadow="lg"
//     transition="all 0.3s"
//     _hover={{
//       transform: "translateY(-5px)",
//       boxShadow: "xl",
//     }}
//   >
//     <VStack spacing={4} align="center">
//       <Flex
//         w="120px"
//         h="120px"
//         bg="white"
//         borderRadius="lg"
//         align="center"
//         justify="center"
//         mb={2}
//         p={3}
//       >
//         <Image
//           src={imageSrc}
//           alt={title}
//           maxW="100%"
//           maxH="100%"
//           objectFit="contain"
//         />
//       </Flex>
//       <Text fontSize="lg" fontWeight="bold" color="#000f40" textAlign="center">
//         {title}
//       </Text>
//       <Text fontSize="sm" color="gray.600" textAlign="center">
//         {description}
//       </Text>
//       {year && (
//         <Text fontSize="sm" color="blue.500" fontWeight="medium">
//           {year}
//         </Text>
//       )}
//     </VStack>
//   </Box>
// );

// const LogoGrid = () => (
//   <SimpleGrid columns={[2, 3, 4, 5]} spacing={8} py={8} px={4}>
//     {[1, 2, 3, 4, 5].map((index) => (
//       <Flex
//         key={index}
//         h="100px"
//         bg="white"
//         borderRadius="md"
//         p={4}
//         align="center"
//         justify="center"
//         transition="all 0.3s"
//         _hover={{ transform: "scale(1.05)" }}
//       >
//         <Box
//           as="img"
//           src={`https://bookingtime.eu/assets/logoairlines/Turkish-Airlines-logo.jpg`}
//           alt={`Partner logo ${index}`}
//           maxW="100%"
//           maxH="100%"
//           objectFit="contain"
//         />
//       </Flex>
//     ))}
//   </SimpleGrid>
// );

// const CertificationsSection = () => {
//   const certifications = [
//     {
//       imageSrc:
//         "https://bookingtime.eu/assets/logoairlines/Turkish-Airlines-logo.jpg",
//       title: "ISO Certification",
//       description: "International Quality Management Standard",
//       year: "2023",
//     },
//     {
//       imageSrc:
//         "https://bookingtime.eu/assets/logoairlines/Turkish-Airlines-logo.jpg",
//       title: "Industry Award",
//       description: "Excellence in Service Delivery",
//       year: "2022",
//     },
//     {
//       imageSrc:
//         "https://bookingtime.eu/assets/logoairlines/Turkish-Airlines-logo.jpg",
//       title: "Top Rated Partner",
//       description: "Recognized for Outstanding Performance",
//       year: "2023",
//     },
//     {
//       imageSrc:
//         "https://bookingtime.eu/assets/logoairlines/Turkish-Airlines-logo.jpg",
//       title: "Best in Category",
//       description: "Leading Industry Recognition",
//       year: "2023",
//     },
//   ];

//   return (
//     <Container maxW="container.lg" py={12}>
//       <Box width={["100%", "100%", "75%"]} mx="auto">
//         <VStack spacing={12}>
//           {/* Certifications Grid */}
//           <SimpleGrid columns={[1, 2, 2, 4]} spacing={8} w="full">
//             {certifications.map((cert, index) => (
//               <CertificationBox
//                 key={index}
//                 imageSrc={cert.imageSrc}
//                 title={cert.title}
//                 description={cert.description}
//                 year={cert.year}
//               />
//             ))}
//           </SimpleGrid>

//           {/* Partner Logos Grid */}
//           <Box w="full">
//             <Text
//               fontSize="xl"
//               fontWeight="bold"
//               color="#000f40"
//               textAlign="center"
//               mb={6}
//             >
//               Our Partners & Accreditations
//             </Text>
//             <LogoGrid />
//           </Box>
//         </VStack>
//       </Box>
//     </Container>
//   );
// };

// export default CertificationsSection;
