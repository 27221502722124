import React from "react";
import { MDBCard, MDBCardImage, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { Box, Text, useBreakpointValue } from "@chakra-ui/react";

const CardIrixPresentation = () => {
  const paragraphSize = useBreakpointValue({ base: "sm", md: "xl" });
  const marginSize = useBreakpointValue({ base: "none", md: "10%" });

  return (
    <>
      <Box p={4}>
        <Box mb={8}>
          <Box
            p={4}
            width={["100%", "58%", "40%"]}
            mb={8}
            marginLeft={marginSize}
            borderWidth="1px"
            borderRadius="md"
            borderStyle="solid"
            borderColor="gray.300"
            boxShadow="0px -2px 14px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1)"
            bg="teal.600"
            color="white"
          >
            <Text fontSize={paragraphSize}>
              Booking Time este o platformă B2B inovatoare, concepută pentru a
              satisface nevoile agențiilor de turism. Cu Booking Time, ai acces
              la o gamă vastă de opțiuni pentru bilete de avion și cazări
              hoteliere, oferite de multipli furnizori de încredere.
            </Text>
          </Box>
        </Box>
        <Box mb={8}>
          <Box
            p={4}
            width={["100%", "58%", "40%"]}
            mb={8}
            ml="auto"
            borderWidth="1px"
            borderRadius="md"
            borderStyle="solid"
            borderColor="gray.300"
            boxShadow="0px -2px 14px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1)"
            bg="teal.600"
            color="white"
          >
            <Text fontSize={paragraphSize}>
              Indiferent dacă ești în căutarea celor mai bune tarife la bilete
              de avion sau dorești să găsești cazări hoteliere ideale pentru
              clienții tăi, Booking Time îți oferă o platformă intuitivă și
              eficientă pentru a căuta și compara opțiuni din diferite surse. Ai
              posibilitatea de a explora diverse destinații și de a alege cele
              mai potrivite variante, adaptate nevoilor și bugetului clienților
              tăi.
            </Text>
          </Box>
        </Box>
        <Box mb={8}>
          <Box
            p={4}
            width={["100%", "58%", "40%"]}
            mb={8}
            marginLeft={marginSize}
            borderWidth="1px"
            borderRadius="md"
            borderStyle="solid"
            borderColor="gray.300"
            boxShadow="0px -2px 14px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1)"
            bg="teal.600"
            color="white"
          >
            <Text fontSize={paragraphSize}>
              Cu Booking Time, ai avantajul de a colabora cu o rețea extinsă de
              furnizori de încredere, ceea ce îți permite să accesezi tarife
              competitive și să beneficiezi de o varietate de opțiuni. Poți
              căuta și compara rapid ofertele de la mai mulți furnizori, astfel
              încât să poți găsi cele mai bune prețuri și disponibilități pentru
              clienții tăi.
            </Text>
          </Box>
        </Box>
        <Box mb={8}>
          <Box
            p={4}
            width={["100%", "58%", "40%"]}
            mb={8}
            ml="auto"
            borderWidth="1px"
            borderRadius="md"
            borderStyle="solid"
            borderColor="gray.300"
            boxShadow="0px -2px 14px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1)"
            bg="teal.600"
            color="white"
          >
            <Text fontSize={paragraphSize}>
              Platforma Booking Time este proiectată pentru a-ți oferi o
              experiență simplă și eficientă. Ai posibilitatea de a gestiona
              rezervările, de a genera rapoarte, și de a urmări în mod facil
              toate detaliile călătoriilor. Totodată, beneficiezi de asistență
              și suport tehnic în cazul în care întâmpini orice dificultăți sau
              ai întrebări.
            </Text>
          </Box>
        </Box>
        <Box mb={8}>
          <Box
            p={4}
            width={["100%", "58%", "40%"]}
            mb={8}
            marginLeft={marginSize}
            borderWidth="1px"
            borderRadius="md"
            borderStyle="solid"
            borderColor="gray.300"
            boxShadow="0px -2px 14px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1)"
            bg="teal.600"
            color="white"
          >
            <Text fontSize={paragraphSize}>
              Cu Booking Time, economisești timp și efort în căutarea celor mai
              bune oferte pentru clienții tăi. Platforma pune la dispoziție
              instrumente avansate de căutare și filtrare, precum și informații
              detaliate despre fiecare opțiune disponibilă. Astfel, poți lua
              decizii informate și să oferi clienților tăi servicii de înaltă
              calitate.
            </Text>
          </Box>
        </Box>
      </Box>

      <MDBRow className="row-cols-1 row-cols-md-2 g-4 w-100 mx-auto">
        <MDBCol>
          <MDBCard>
            <MDBCardImage
              src="../images/b2b-page/irix.PNG"
              alt="..."
              position="top"
            />
          </MDBCard>
        </MDBCol>
        <MDBCol>
          <MDBCard>
            <MDBCardImage
              src="../images/b2b-page/irix-1.PNG"
              alt="..."
              position="top"
            />
          </MDBCard>
        </MDBCol>
        <MDBCol>
          <MDBCard>
            <MDBCardImage
              src="../images/b2b-page/irix-2.PNG"
              alt="..."
              position="top"
            />
          </MDBCard>
        </MDBCol>
        <MDBCol>
          <MDBCard>
            <MDBCardImage
              src="../images/b2b-page/irix-3.PNG"
              alt="..."
              position="top"
            />
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default CardIrixPresentation;


