import React from "react";
import {
  Box,
  Container,
  Grid,
  GridItem,
  Heading,
  VStack,
  List,
  ListItem,
  ListIcon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  Globe,
  Users,
  BarChart,
  CheckCircle2,
  CreditCard,
  FileText,
  Calendar,
  Palette,
  Mail,
  UserCheck,
  HeartHandshake,
  UserPlus,
  ClipboardCheck,
  BadgeCheck,
  LineChart,
  MessageCircle,
} from "lucide-react";

const EventCategories = () => {
  const bgColor = useColorModeValue("gray.50", "gray.900");
  const cardBg = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.700");

  const categories = [
    {
      title: "Website-uri personalizate pentru evenimente",
      icon: Globe,
      items: [
        {
          text: "Gestionarea înregistrărilor",
          icon: UserCheck,
          description:
            "Simplifică înscrierile participanților cu instrumente intuitive de înregistrare.",
        },
        {
          text: "Plăți securizate",
          icon: CreditCard,
          description:
            "Asigură tranzacții rapide, sigure și de încredere pentru toți participanții.",
        },
        {
          text: "Formulare de înregistrare personalizate",
          icon: FileText,
          description:
            "Colectează date precise prin formulare adaptate nevoilor specifice ale evenimentului tău.",
        },
        {
          text: "Distribuirea agendei",
          icon: Calendar,
          description:
            "Transmite agenda evenimentului cu ușurință participanților, asigurând informarea completă a acestora.",
        },
        {
          text: "Design adaptabil personalizat",
          icon: Palette,
          description:
            "Creează website-uri atractive și complet adaptabile, adaptate brandului tău.",
        },
      ],
    },
    {
      title: "Experiența participanților la evenimente",
      icon: Users,
      items: [
        {
          text: "Instrumente de marketing",
          icon: Mail,
          description:
            "Crește participarea și interacțiunea cu ajutorul campaniilor de marketing integrate.",
        },
        {
          text: "Gestionarea sponsorilor",
          icon: HeartHandshake,
          description:
            "Facilitează parteneriatele printr-o coordonare eficientă a sponsorilor.",
        },
        {
          text: "Managementul participanților",
          icon: UserPlus,
          description:
            "Monitorizează înregistrările, preferințele și prezența participanților cu ușurință.",
        },
        {
          text: "Primirea participanților și asistență",
          icon: ClipboardCheck,
          description:
            "Asigură check-in-uri rapide și o atmosferă primitoare cu suport dedicat la locație.",
        },
        {
          text: "Înregistrare și urmărire la fața locului",
          icon: BadgeCheck,
          description:
            "Gestionează înscrierile pe loc și urmărește în timp real activitatea participanților.",
        },
      ],
    },
    {
      title: "Informații și analize detaliate",
      icon: BarChart,
      items: [
        {
          text: "Chestionare",
          icon: MessageCircle,
          description:
            "Colectează feedback și informații în timp real prin sondaje interactive.",
        },
        {
          text: "Sondaje de satisfacție",
          icon: CheckCircle2,
          description:
            "Măsoară instant nivelul de satisfacție al participanților pentru a identifica zonele care necesită îmbunătățiri.",
        },
        {
          text: "Controlul costurilor",
          icon: CreditCard,
          description:
            "Obține vizibilitate asupra cheltuielilor cu ajutorul rapoartelor detaliate și al instrumentelor de analiză.",
        },
        {
          text: "Email marketing",
          icon: Mail,
          description:
            "Menține legătura cu audiența înainte, în timpul și după eveniment.",
        },
        {
          text: "Monitorizarea indicatorilor",
          icon: LineChart,
          description:
            "Urmărește și analizează indicatorii de performanță cheie pentru a evalua succesul evenimentului.",
        },
      ],
    },
  ];

  return (
    <Box py={{ base: 12, md: 20 }} bg={bgColor}>
      <Container maxW="container.xl">
        <Grid
          templateColumns={{ base: "1fr", lg: "repeat(3, 1fr)" }}
          gap={{ base: 8, lg: 6 }}
        >
          {categories.map((category, idx) => (
            <GridItem
              key={idx}
              bg={cardBg}
              p={8}
              borderRadius="xl"
              borderWidth="1px"
              borderColor={borderColor}
              boxShadow="xl"
              transition="all 0.3s"
              _hover={{ transform: "translateY(-4px)", boxShadow: "2xl" }}
            >
              <VStack spacing={6} align="start">
                <Box
                  w="full"
                  display="flex"
                  alignItems="center"
                  gap={3}
                  pb={4}
                  borderBottomWidth="2px"
                  borderColor="blue.500"
                >
                  <category.icon size={28} color="#3182CE" />
                  <Heading
                    as="h3"
                    size="md"
                    color="blue.600"
                    fontFamily="Montserrat"
                  >
                    {category.title}
                  </Heading>
                </Box>

                <List spacing={6} w="full">
                  {category.items.map((item, itemIdx) => (
                    <ListItem
                      key={itemIdx}
                      display="flex"
                      alignItems="flex-start"
                      gap={3}
                    >
                      <ListIcon
                        as={item.icon}
                        color="blue.500"
                        mt={1}
                        boxSize={5}
                      />
                      <Box>
                        <Text
                          fontWeight="600"
                          color="gray.700"
                          fontSize="md"
                          mb={1}
                        >
                          {item.text}
                        </Text>
                        <Text
                          color="gray.600"
                          fontSize="sm"
                          lineHeight="tall"
                          fontFamily="Montserrat"
                        >
                          {item.description}
                        </Text>
                      </Box>
                    </ListItem>
                  ))}
                </List>
              </VStack>
            </GridItem>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default EventCategories;
