import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  IconButton,
  Button,
  useBreakpointValue,
} from "@chakra-ui/react";
import { FaBars, FaArrowUp } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useMediaQuery } from "@chakra-ui/react";
import {
  FaFacebookF,
  FaInstagram,
  FaWhatsapp,
  FaEnvelope,
  FaPhoneAlt,
} from "react-icons/fa";
import { extendTheme } from "@chakra-ui/react";
import "./headerNavBarNew.css";

const NavbarHeader = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  // variabila pentru hamburger menu
  const [showMenu, setShowMenu] = useState(false);
  // variabila pentru butoane medica in varianta mobil
  const showSocialMediaIcons = useBreakpointValue({ base: true, md: false });
  // variabila pentru butoane medica in varianta desktop
  const showIcons = useBreakpointValue({ base: false, lg: true });
  // variabila pentru meniu in timp ce se da scroll
  // const [isMenuVisible, setIsMenuVisible] = useState(true);
  // variabila pentru butonul de Scroll To Top
  const [showButtonScrollToTop, setShowButtonScrollToTop] = useState(false);
  // variabila pentru mobil dezactivare position fixed la navbar
  const [isLargerThanBreakpoint] = useMediaQuery("(min-width: 768px)");

  // Functia handleClick se activeaza cand se da click pe butonul cu 3 linii (hamburger menu)
  function handleClick() {
    setShowMenu(!showMenu);
  }

  const handleScroll = () => {
    if (window.pageYOffset > window.innerHeight) {
      // setIsMenuVisible(false);
      setShowButtonScrollToTop(true);
    } else {
      // setIsMenuVisible(true);
      setShowButtonScrollToTop(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleButtonClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const theme = extendTheme({
    colors: {
      instagramPink: "#E1306C", // Pink
      facebookBlue: "#1877F2", // Facebook blue
      whatsappGreen: "#25D366", // WhatsApp green
    },
  });

  // const ExternalLink = ({ to, children }) => (
  //   <Link
  //     to={to}
  //     style={{ color: "inherit", textDecoration: "inherit" }}
  //     onClick={scrollToTop} // Optionally scroll to top when the link is clicked
  //   >
  //     {children}
  //   </Link>
  // );

  return (
    <>
      {/* Butoanele sociale (pentru dispozitivele mici ) */}
      {showSocialMediaIcons && (
        <Flex
          align="center"
          justify="center"
          bg="gray.800"
          color="white"
          padding="0.5rem"
          borderBottom="2px"
          backgroundColor="gray.300"
          position="sticky"
          top="0"
          zIndex="100"
        >
          <IconButton
            as="a"
            href="https://www.facebook.com/traveltimeromania"
            target="_blank"
            variant="outline"
            bg={theme.colors.facebookBlue}
            aria-label="Facebook"
            icon={<FaFacebookF fontSize="1.5rem" />}
            size="sm"
            mx="1"
          />

          <IconButton
            as="a"
            href="https://www.instagram.com/traveltime.ro/"
            target="_blank"
            variant="outline"
            bg={theme.colors.instagramPink}
            aria-label="Instagram"
            icon={<FaInstagram fontSize="1.5rem" />}
            size="sm"
            mx="1"
          />
          <IconButton
            as="a"
            href="mailto:turism@travel-time.ro"
            target="_blank"
            variant="outline"
            bg="gray"
            aria-label="Instagram"
            icon={<FaEnvelope fontSize="1.5rem" />}
            size="sm"
            mx="1"
          />
          <IconButton
            as="a"
            href="tel:0371 121 272"
            target="_blank"
            variant="outline"
            bg="gray"
            aria-label="Instagram"
            icon={<FaPhoneAlt fontSize="1.5rem" />}
            size="sm"
            mx="1"
          />

          <IconButton
            as="a"
            href="https://wa.me/40721242233"
            target="_blank"
            variant="outline"
            bg={theme.colors.whatsappGreen}
            aria-label="WhatsApp"
            icon={<FaWhatsapp fontSize="2rem" />}
            size="md"
            ml="auto"
          />
        </Flex>
      )}

      {/* Logo */}

      <Flex
        as="nav"
        align="center"
        justify="space-between"
        wrap="wrap"
        color="black"
        // boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
        position={isLargerThanBreakpoint ? "sticky" : undefined}
        top="0"
        zIndex="100"
        backgroundColor="white"
      >
        <Box>
          <Link to={"/"}>
            <img
              id="logo"
              className="logo"
              alt="LOGO Travel-time.ros"
              src="/traveltime-logo.png"
              width={"200px"}
            />
          </Link>
        </Box>

        {/* Hamburger menu (pentru dispozitivele mici) */}

        <Box display={{ base: "block", md: "none" }} onClick={handleClick}>
          <Flex align="center" justify="center">
            {" "}
            {/* Vertical center */}
            <IconButton
              aria-label="Navigation menu"
              icon={<FaBars />}
              size="lg"
            />
          </Flex>
        </Box>

        {/* Meniul (pentru dispozitivele mari) */}
        {/* {isMenuVisible && ( */}
        <>
          <Box
            className="navbar"
            display={{ base: showMenu ? "flex" : "none", md: "flex" }}
            flexDirection={{ base: "column", md: "row" }}
            width={{ base: "100%", md: "auto" }}
            alignItems={{ base: "flex-start", md: "unset" }}
            flexGrow={{ base: 1, md: 0 }}
            justifyContent={{ base: "center", md: "flex-end" }} // Aliniere la dreapta pentru dispozitivele mari
          >
            <Link
              to={"/"}
              style={{ color: "inherit", textDecoration: "inherit" }}
              onClick={() => scrollToTop()}
            >
              <Button fontSize="lg" variant="ghost" mr={0}>
                Acasă
              </Button>
            </Link>

            {/* <Link
              to={"/despre"}
              style={{ color: "inherit", textDecoration: "inherit" }}
              onClick={() => scrollToTop()}
            >
              <Button fontSize="lg" variant="ghost" mr={0}>
                Despre
              </Button>
            </Link> */}
            <Link
              to={"/servicii"}
              style={{ color: "inherit", textDecoration: "inherit" }}
              onClick={() => scrollToTop()}
            >
              <Button fontSize="lg" variant="ghost" mr={0}>
                Servicii
              </Button>
            </Link>

            {/* <Link
              to={"/blog"}
              style={{ color: "inherit", textDecoration: "inherit" }}
              onClick={() => scrollToTop()}
            >
              <Button variant="green" mr={0} >
                Blog
              </Button>
            </Link> */}

            <Link
              to={"/consultanta"}
              style={{ color: "inherit", textDecoration: "inherit" }}
              onClick={() => scrollToTop()}
            >
              <Button fontSize="lg" variant="ghost" mr={0}>
                Consultanță
              </Button>
            </Link>

            <Link
              to={"/agentii-partenere"}
              style={{ color: "inherit", textDecoration: "inherit" }}
              onClick={() => scrollToTop()}
            >
              <Button fontSize="lg" variant="ghost" mr={0}>
                Booking Time
              </Button>
            </Link>

            <Link
              to={"/contact"}
              style={{ color: "inherit", textDecoration: "inherit" }}
              onClick={() => scrollToTop()}
            >
              <Button fontSize="lg" variant="ghost" mr={0}>
                Contact
              </Button>
            </Link>
          </Box>
        </>
        {/* )} */}
        {/* Butoanele sociale (pentru dispozitivele mici si mari) */}
        <Box
          display={{ base: showMenu ? "block" : "none", md: "flex" }}
          width={{ base: "100%", md: "auto" }}
          alignItems={{ base: "center", md: "unset" }}
          flexGrow={{ base: 1, md: 0 }}
          justifyContent={{ base: "none", lg: "flex-end" }} // Aliniere la dreapta pentru dispozitivele mari
        >
          {showIcons && (
            <Flex align="center" justify="center">
              <IconButton
                as="a"
                href="https://www.facebook.com/traveltimeromania"
                target="_blank"
                variant="outline"
                colorScheme="teal"
                aria-label="Facebook"
                icon={<FaFacebookF font-size="1.5rem" />}
                mx="1"
              />
              <IconButton
                as="a"
                href="https://www.instagram.com/traveltime.ro/"
                target="_blank"
                variant="outline"
                colorScheme="teal"
                aria-label="Instagram"
                icon={<FaInstagram font-size="1.5rem" />}
                mx="1"
              />
            </Flex>
          )}
        </Box>
        <Box
          position="fixed"
          bottom="1rem"
          right="1rem"
          opacity={showButtonScrollToTop ? 1 : 0}
          transition="opacity 0.3s ease-in-out"
        >
          {/* <TawkConnection /> */}
          <IconButton
            aria-label="Scroll to top"
            icon={<FaArrowUp />}
            size="lg"
            isRound={true}
            onClick={handleButtonClick}
          />
        </Box>
      </Flex>
    </>
  );
};

export default NavbarHeader;
