import React from "react";
import {
  Box,
  Container,
  Heading,
  Text,
  Grid,
  GridItem,
  VStack,
  HStack,
  Flex,
  Circle,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  Compass,
  TrendingUp,
  Target,
  Sparkles,
  Building2,
  Calendar,
  ArrowUpRight,
  Map,
} from "lucide-react";

const FutureSection = () => {
  const bgColor = useColorModeValue("white", "gray.900");
  const cardBg = useColorModeValue("blue.50", "blue.900");
  const accentColor = useColorModeValue("blue.500", "blue.300");
  const textColor = useColorModeValue("gray.700", "gray.200");

  const futureAspects = [
    {
      icon: Building2,
      title: "Deschideri de locații",
      description: "Extindere strategică și oportunități în noi piețe",
    },
    {
      icon: Sparkles,
      title: "Produse noi",
      description: "Inovații și soluții adaptate nevoilor în evoluție",
    },
    {
      icon: Calendar,
      title: "Evenimente corporate",
      description: "Optimizarea și scalarea evenimentelor de afaceri",
    },
  ];

  return (
    <Box py={{ base: 12, md: 20 }} bg={bgColor} overflow="hidden">
      <Container maxW="container.xl" position="relative">
        {/* Background Decorative Elements */}
        <Box
          position="absolute"
          right="-10%"
          top="0"
          w="500px"
          h="500px"
          opacity={0.05}
          transform="rotate(45deg)"
        >
          <Map size={500} />
        </Box>

        <Grid
          templateColumns={{ base: "1fr", lg: "1fr 1fr" }}
          gap={{ base: 10, lg: 16 }}
        >
          {/* Left Content Section */}
          <GridItem position="relative">
            <VStack align="start" spacing={8}>
              <Flex
                align="center"
                bg="blue.500"
                color="white"
                p={4}
                borderRadius="xl"
                position="relative"
                overflow="hidden"
              >
                <Box position="absolute" right="-2" top="-2" opacity={0.2}>
                  <ArrowUpRight size={80} />
                </Box>
                <HStack spacing={4}>
                  <Compass size={32} />
                  <Heading as="h2" size="lg" fontFamily="Montserrat">
                    Perspective și pregătire pentru viitor
                  </Heading>
                </HStack>
              </Flex>

              <Text
                fontSize="lg"
                color={textColor}
                lineHeight="tall"
                fontFamily="Montserrat"
              >
                Prin analizarea tendințelor din domeniul călătoriilor de afaceri
                și anticiparea schimbărilor din industrie, ajutăm clienții să se
                adapteze oportunităților emergente și să își rafineze
                strategiile de călătorie.
              </Text>

              <Text
                fontSize="lg"
                color={textColor}
                lineHeight="tall"
                fontFamily="Montserrat"
              >
                Fie că este vorba despre produse noi, deschideri de locații sau
                creșterea succesului evenimentelor corporate, serviciile noastre
                de consultanță oferă informații scalabile, de înaltă calitate,
                și soluții personalizate, adaptate obiectivelor tale.
              </Text>

              {/* Key Metrics */}
              <Grid templateColumns="repeat(2, 1fr)" gap={6} w="full" mt={4}>
                {[
                  { icon: TrendingUp, label: "Analiză Tendințe" },
                  { icon: Target, label: "Strategii Adaptate" },
                ].map((metric, idx) => (
                  <Box
                    key={idx}
                    p={6}
                    borderRadius="lg"
                    bg={cardBg}
                    textAlign="center"
                    transition="all 0.3s"
                    _hover={{
                      transform: "translateY(-4px)",
                      boxShadow: "lg",
                    }}
                  >
                    <VStack spacing={3}>
                      <Circle size="50px" bg="white">
                        <metric.icon size={24} color="#3182CE" />
                      </Circle>
                      <Text
                        fontWeight="bold"
                        color="blue.700"
                        fontFamily="Montserrat"
                      >
                        {metric.label}
                      </Text>
                    </VStack>
                  </Box>
                ))}
              </Grid>
            </VStack>
          </GridItem>

          {/* Right Cards Section */}
          <GridItem>
            <VStack spacing={{ base: 6, md: 14 }} align="stretch">
              {futureAspects.map((aspect, idx) => (
                <Box
                  key={idx}
                  p={9}
                  bg="white"
                  borderRadius="xl"
                  boxShadow="xl"
                  borderWidth="1px"
                  borderColor="gray.100"
                  position="relative"
                  overflow="hidden"
                  transition="all 0.3s"
                  _hover={{
                    transform: "translateX(8px)",
                    boxShadow: "2xl",
                  }}
                >
                  <HStack spacing={4}>
                    <Circle size="48px" bg="blue.50">
                      <aspect.icon size={24} color="#3182CE" />
                    </Circle>
                    <VStack align="start" spacing={1}>
                      <Text
                        fontSize="lg"
                        fontWeight="bold"
                        color="blue.700"
                        fontFamily="Montserrat"
                      >
                        {aspect.title}
                      </Text>
                      <Text color={textColor} fontFamily="Montserrat">
                        {aspect.description}
                      </Text>
                    </VStack>
                  </HStack>
                  <Box
                    position="absolute"
                    right="-4"
                    bottom="-4"
                    opacity={0.05}
                  >
                    <aspect.icon size={100} />
                  </Box>
                </Box>
              ))}
            </VStack>
          </GridItem>
        </Grid>
      </Container>
    </Box>
  );
};

export default FutureSection;
