import React from "react";
import {
  Box,
  Container,
  Heading,
  Text,
  Grid,
  GridItem,
  VStack,
  HStack,
  Flex,
  Circle,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  ScrollText,
  Settings,
  UserCog,
  ClipboardCheck,
  ArrowRight,
  BarChart3,
  RefreshCw,
} from "lucide-react";

const TravelPolicySection = () => {
  const bgColor = useColorModeValue("gray.50", "gray.900");
  const cardBg = useColorModeValue("white", "gray.800");
  const accentColor = useColorModeValue("blue.500", "blue.300");
  const textColor = useColorModeValue("gray.700", "gray.200");

  const processSteps = [
    {
      icon: UserCog,
      title: "Evaluare și Analiză",
      description:
        "Managerul de cont evaluează procesele existente și identifică oportunități de îmbunătățire.",
    },
    {
      icon: Settings,
      title: "Implementare Soluții",
      description:
        "Implementăm instrumente și politici inovatoare adaptate nevoilor specifice.",
    },
    {
      icon: BarChart3,
      title: "Monitorizare Rezultate",
      description: "Urmărim și măsurăm impactul schimbărilor implementate.",
    },
    {
      icon: RefreshCw,
      title: "Optimizare Continuă",
      description: "Rafinăm strategia în mod constant pentru rezultate optime.",
    },
  ];

  return (
    <Box py={{ base: 12, md: 20 }} bg={bgColor}>
      <Container maxW="container.xl">
        <Grid
          templateColumns={{ base: "1fr", lg: "5fr 4fr" }}
          gap={{ base: 10, lg: 16 }}
        >
          {/* Left Content Section */}
          <GridItem>
            <VStack align="start" spacing={8}>
              <HStack spacing={4}>
                <Circle size="48px" bg="blue.500" color="white">
                  <ScrollText size={24} />
                </Circle>
                <Heading
                  as="h2"
                  size="xl"
                  color="blue.600"
                  fontFamily="Montserrat"
                >
                  Politică de călătorii de afaceri personalizată
                </Heading>
              </HStack>

              <Text
                fontSize="lg"
                color={textColor}
                lineHeight="tall"
                fontFamily="Montserrat"
              >
                Colaborăm îndeaproape cu clienții pentru a introduce
                instrumente, procese și politici inovatoare, orientând călătorii
                spre luarea unor decizii mai bune de rezervare. Un Manager de
                cont dedicat evaluează impactul acestor soluții, identificând
                noi oportunități de îmbunătățire a performanței sau de creștere
                a eficienței proceselor.
              </Text>

              <Text
                fontSize="lg"
                color={textColor}
                lineHeight="tall"
                fontFamily="Montserrat"
              >
                De asemenea, sprijinim implementarea soluțiilor agreate,
                împărtășind cele mai bune practici, monitorizând rezultatele și
                rafinând strategia pe parcurs, pentru a asigura succesul pe
                termen lung.
              </Text>

              {/* Features Grid */}
              <Grid
                templateColumns={{ base: "1fr", sm: "repeat(2, 1fr)" }}
                gap={6}
                w="full"
                mt={4}
              >
                {[
                  { icon: ClipboardCheck, text: "Politici personalizate" },
                  { icon: UserCog, text: "Manager de cont dedicat" },
                  { icon: Settings, text: "Procese optimizate" },
                  { icon: BarChart3, text: "Monitorizare performanță" },
                ].map((feature, idx) => (
                  <Box
                    key={idx}
                    p={4}
                    borderWidth="1px"
                    borderRadius="lg"
                    borderColor="blue.200"
                    bg="blue.50"
                  >
                    <HStack spacing={3}>
                      <feature.icon size={20} color="#3182CE" />
                      <Text
                        color="blue.700"
                        fontWeight="medium"
                        fontFamily="Montserrat"
                      >
                        {feature.text}
                      </Text>
                    </HStack>
                  </Box>
                ))}
              </Grid>
            </VStack>
          </GridItem>

          {/* Right Process Section */}
          <GridItem>
            <VStack
              spacing={0}
              position="relative"
              align="stretch"
              bg={cardBg}
              borderRadius="xl"
              boxShadow="xl"
              p={8}
            >
              {processSteps.map((step, idx) => (
                <React.Fragment key={idx}>
                  <Flex gap={4} position="relative" zIndex={1}>
                    <VStack spacing={0} align="center">
                      <Circle
                        size="40px"
                        bg="blue.500"
                        color="white"
                        fontSize="lg"
                        fontWeight="bold"
                      >
                        {idx + 1}
                      </Circle>
                      {idx < processSteps.length - 1 && (
                        <Box w="2px" h="100px" bg="blue.200" my={2} />
                      )}
                    </VStack>
                    <Box pt={2} pb={8}>
                      <HStack spacing={2} mb={2}>
                        <step.icon size={20} color="#3182CE" />
                        <Text
                          fontSize="lg"
                          fontWeight="bold"
                          color="blue.700"
                          fontFamily="Montserrat"
                        >
                          {step.title}
                        </Text>
                      </HStack>
                      <Text color={textColor} fontFamily="Montserrat">
                        {step.description}
                      </Text>
                    </Box>
                  </Flex>
                </React.Fragment>
              ))}
            </VStack>
          </GridItem>
        </Grid>
      </Container>
    </Box>
  );
};

export default TravelPolicySection;
