import React from "react";
import { Box, Flex, Spacer, Image, Heading } from "@chakra-ui/react";

const HeaderConsultancy = () => {
  return (
    <Box
      backgroundImage="url('https://travel-time.ro/images/newImages/shutterstock_670699621.jpg')"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      py="240px"
    >
      <Flex
        maxW="container.lg"
        mx="auto"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Heading
          as="h1"
          color="white"
          fontSize={["4xl", "5xl", "8xl"]}
          textAlign="center"
          mb={4}
          fontFamily="'Montserrat', sans-serif"
        >
          Consultanță
        </Heading>
        {/* <Heading
          as="h2"
          color="white"
          fontSize={["xl", "2xl", "4xl"]}
          textAlign="center"
          fontFamily="'Montserrat', sans-serif"
        >
          Managementul călătoriilor de afaceri
        </Heading> */}
      </Flex>
    </Box>
  );
};

export default HeaderConsultancy;
